<!--
 * @Author: lbh
 * @Date: 2021-04-23 18:16:52
 * @LastEditors: lbh
 * @LastEditTime: 2024-02-29 14:58:59
 * @Description: 關於
-->
<template>
  <div
    class="products t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="about"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        :title="$t('about.bigTitle')"
        :type="1"
        :image="getImgPath('about_gingersoftBG.jpg')"
      />
      <selfGraphic
        :type="window.type"
        :imgs="graphic.imgs"
        :datas="graphic.datas"
        :height="graphic.height"
        :logo="graphic.logo"
        :bgImg="graphic.bgImg"
        :but="graphic.but"
      />

    </div>
    <selfFooter :type="window.type" />
    <self-contact v-if="!isRicepon" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import selfGraphic from "@/components/commo/graphic";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfGraphic
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      graphic: {
        imgs: [],
        datas: [],
        height: 0,
        logo: '',
        bgImg: '',
        but: {
          bgImg: '',
          img: '',
          title: '',
          content: ''
        }
      },
      isRicepon: false
    }
  },
  mounted () {
    util.setTitle(`${this.$t('about.title')} – ${this.$t('companyName')}`);
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    let isRicepon = util.isRicepon();
    let isRiceponCn = util.isRiceponCn();
    this.isRicepon = isRicepon;
    // 初始化數據
    this.graphic.logo = util.getImgPath('ricepon_logo.png');
    this.graphic.bgImg = util.getImgPath('bg7-1.jpg');
    let imgs = [
      util.getImgPath('about_3.png')];
    this.graphic.imgs = imgs;

    let datas = [];
    if (isRicepon) {
      let one = {
        title: this.$t('about.labelA.title'),
        items: [
          { title: this.$t('about.labelA.itemA') },
          { title: isRiceponCn ? this.$t('about.labelA.itemBRiceponCn') : this.$t('about.labelA.itemB'), style: 'margin-top:25px;' },
          { title: '', style: 'height:1px;background-color:#ddd;margin-top:25px;' }
        ]
      };

      let two = {
        title: this.$t('about.labelB.title'), items: [
          { title: this.$t('about.labelB.itemA') },
        ],
      }
      let three = {
        title: '', items: [
          { title: `●  ${this.$t('about.labelC.itemA')}` },
          { title: `●  ${this.$t('about.labelC.itemB')}` },
          { title: `●  ${this.$t('about.labelC.itemC')}` },
          { title: `●  ${this.$t('about.labelC.itemD')}` },
        ],
      }
      datas.push(one);
      datas.push(two);
      datas.push(three);
    } else {
      let one = {
        title: "公司簡介",
        items: [
          { title: 'Ricepon 飯糰在2012年於香港成立，多年來一直專注於研究及開發餐飲管理系統，擁有豐富的行業經驗及服務多所小型、大型及連鎖餐廳，致力為每位客戶提供客製化的智能餐飲管理方案，務求為客戶節省人力成本、提高經營效率！我們更擁有自己的研發團隊，能及時有效地因應社會發展需要為每位客戶設計出最合適的產品！' },
        ]
      }
      let two = {
        title: '經營理念', items: [
          { title: '清楚知道餐飲業面臨“租金高 人工高 食材成本高 利潤低”的三高一低痛點，因應全球發展趨勢各行各業的戰場亦從線下實體店已經延伸至線上外賣店。為了符合時代發展需要，創新推出雲端餐飲管理系統，結合掃碼落單及會員系統，助每位老闆從經營到營銷到營利！' },
        ],
      }
      datas.push(one);
      datas.push(two);
    }

    this.graphic.datas = datas;
    this.graphic.but.bgImg = util.getImgPath('gingersoft_web_bg02.jpg');
    this.graphic.but.img = util.getImgPath('14631-Converted-01.png');
    this.graphic.but.title = this.$t('about.labelD.title');
    this.graphic.but.content = this.$t('about.labelD.desc');
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.5);
      if (w < 700) {
        this.window.type = '600';
        this.graphic.height = (w * 0.8 - 40) * 0.8;
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.graphic.height = (w * 0.8 - 40) * 0.8;
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
        this.graphic.height = (w * 0.8 / 2 - 40) * 0.8;
        this.window.class_ = '';
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    }
  }
}
</script>
<style lang="less" scoped>
</style>
